/* You can add global styles to this file, and also import other style files */
@import "./assets/index.css";
@import "./assets/dbstyle/diagrambuilder.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
:root {
  --dark-green: #167073;
  --light-green: #1ED995;
  --dark-gray: #4D4D4D;
  --dark-gray-opaque: #4d4d4d4d;
  --light-gray: #b3b3b3;
}

.ligth-green {
  color: var(--light-green) !important;
}

.dark-green {
  color: var(--dark-green) !important;
}

.gray {
  color: #868686;
}

.light-gray {
  color: #b3b3b3;
}

body {
  background-color: #f5f7fb;
}

.router-content > app-dashboard {
  width: 100%;
}

.router-content > app-template {
  width: 100%;
}

.cdk-dialog-container {
  outline: none !important;
}

.button-primary {
  color: var(--dark-green);
  background: #D5E5E5;
  border-radius: 15px;
  padding: 12px 18px;
  border: none;
  font-size: 1.05rem;
  font-weight: 500;
}

.button-primary:hover {
  background: var(--dark-green);
  color: white;
}

.svg-to-dark-green {
  filter: brightness(0) saturate(100%) invert(28%) sepia(89%) saturate(417%) hue-rotate(133deg) brightness(103%) contrast(87%);
}

.svg-to-dark-gray {
  filter: brightness(0) saturate(100%) invert(33%) sepia(15%) saturate(7%) hue-rotate(316deg) brightness(87%) contrast(96%);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

#sing-canvas .card .card-header {
  background: transparent;
  color: #4D4D4D;
}

#sing-canvas .card .card-header button {
  border: none;
  background: transparent;
  color: #4D4D4D;
}

#sing-canvas .card .card-header button i {
  color: var(--light-green);
}

#sing-canvas .card .card-body #canvasPainterView {
  width: 110%;
}

.image .image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.image.image_resized img {
  width: 100%;
}

.image.image_resized figcaption {
  display: block;
  caption-side: bottom;
  word-break: break-word;
  color: #333;
  background-color: #f7f7f7;
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
  text-align: center;
}

.table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px double #b3b3b3;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table table td,
.table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 10000 !important;
}

.text-huge {
  font-size: 1.8em;
}

.text-big {
  font-size: 1.4em;
}

.text-small {
  font-size: 0.85em;
}

.text-tiny {
  font-size: 0.7em;
}

/*chackboxes style */
.checkbox {
  width: 100%;
  margin: 0px;
  position: relative;
  display: block;
  white-space: nowrap;
  font-size: 12px;
  color: #878787;
}

.checkbox input[type=checkbox] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox label {
  position: relative;
}

.checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 1px solid #878787;
}

.checkbox label:after {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid var(--light-green);
  border-left: 2px solid var(--light-green);
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 8px;
  left: 7px;
}

.checkbox input[type=checkbox]:checked ~ label::before {
  color: var(--light-green);
}

.checkbox input[type=checkbox]:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 23px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: middle;
}

.checkbox label span {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox input[type=checkbox]:focus + label::before {
  outline: 0;
}

.preview-modal {
  height: 80% !important;
  width: 95vw !important;
}

.preview-content {
  height: 100% !important;
  width: 100% !important;
}

.assesment-modal {
  height: 80% !important;
  width: 95vw !important;
}

.assesment-modal-content {
  height: 100% !important;
  width: 100% !important;
}

.permissions-modal {
  height: fit-content !important;
  width: 95vw !important;
}

.permissions-content {
  height: fit-content !important;
  width: 100% !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #F6F7Fb;
}

.content-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.content-select select::-ms-expand {
  display: none;
}

.nsm-content {
  padding: 30px;
  border-radius: 15px;
  max-height: 90vh !important;
  overflow-y: auto;
}

.nsm-dialog {
  max-width: 80vw;
}

.image-selected {
  border-color: turquoise;
  border-width: 3px;
}

.image-container {
  padding-right: 5px;
  padding-bottom: 5px;
  margin: auto;
}

.image-size {
  max-height: 128px;
}

.grid {
  margin-left: 0px !important;
}

.alias {
  cursor: alias;
}

.all-scroll {
  cursor: all-scroll;
}

.auto {
  cursor: auto;
}

.cell {
  cursor: cell;
}

.context-menu {
  cursor: context-menu;
}

.col-resize {
  cursor: col-resize;
}

.copy {
  cursor: copy;
}

.crosshair {
  cursor: crosshair;
}

.default {
  cursor: default;
}

.e-resize {
  cursor: e-resize;
}

.ew-resize {
  cursor: ew-resize;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.help {
  cursor: help;
}

.move {
  cursor: move;
}

.n-resize {
  cursor: n-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nesw-resize {
  cursor: nesw-resize;
}

.ns-resize {
  cursor: ns-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.nwse-resize {
  cursor: nwse-resize;
}

.no-drop {
  cursor: no-drop;
}

.none {
  cursor: none;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.row-resize {
  cursor: row-resize;
}

.s-resize {
  cursor: s-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.text {
  cursor: text;
}

.w-resize {
  cursor: w-resize;
}

.wait {
  cursor: wait;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.loader-logo {
  height: 20vh;
}

.logo-animation {
  animation-name: logo_animation;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: logo_animation;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

.tableFixHead {
  overflow-y: auto;
  height: 200px;
}

.tableFixHead thead th {
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: #ffffff !important;
  text-align: center !important;
}

#graphDiv .node rect,
#graphDiv .node circle,
#graphDiv .node ellipse,
#graphDiv .node polygon,
#graphDiv .node path {
  fill: var(--light-green) !important;
  stroke: #00ffff !important;
  stroke-width: 1px !important;
}

#graphDiv .edgeLabel {
  background-color: white !important;
  text-align: center !important;
}

#graphDiv1 .node rect,
#graphDiv1 .node circle,
#graphDiv1 .node ellipse,
#graphDiv1 .node polygon,
#graphDiv1 .node path {
  fill: var(--light-green) !important;
  stroke: #00ffff !important;
  stroke-width: 1px !important;
}

#graphDiv1 .edgeLabel {
  background-color: white !important;
  text-align: center !important;
}

@-moz-keyframes logo_animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes logo_animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logo_animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.signaturepad-container ng-signature-pad .signature-pad canvas {
  height: 150px;
  width: 100%;
}

.signaturepad-container-profile ng-signature-pad .signature-pad canvas {
  height: 110px;
  width: 100%;
}

/* generate action modal */
.generate-action-modal {
  height: 76vh !important;
  width: 95vw !important;
}

.generate-action-content {
  height: 78vh !important;
  width: 100% !important;
}

.generate-action-content .modal-header {
  height: 10%;
}

.generate-action-content .modal-body {
  height: 80% !important;
  max-height: 80% !important;
  overflow-y: auto !important;
}

.generate-action-content .modal-footer {
  height: 10% !important;
  border-top: 1px solid #d6d6d6 !important;
}

.problematic-images-container {
  width: 100% !important;
  border: none !important;
}

.problematic-image-container {
  width: 34px;
  height: 27px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.upload-image-problematic {
  width: 100%;
  height: 100%;
}

.remove-description-image {
  font-size: 1.2rem;
  position: absolute;
  top: -7px;
  right: -10px;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.details-label-container {
  background-color: #ffffff;
  font-weight: 500;
  font-size: 1.1rem;
  color: #4D4D4D;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
}

.details-label-container .fa-angle-down,
.details-label-container .fa-angle-up {
  color: var(--light-green) !important;
  font-size: 1.7rem;
  position: absolute;
  top: 15%;
  right: 4%;
}

.details-label-container .fa-angle-up {
  color: #ffffff;
}

.details-container {
  padding: 20px;
  border: 1px solid #b6b6b6;
  border-radius: 15px;
  margin-top: 15px;
}

.details-assing-select {
  height: calc(1.5em + 0.75rem + 2px);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.uploaded-image-border {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.add-description-photo {
  width: 25px;
  height: 25px;
}

.attach-photo-label {
  color: var(--light-green);
  font-weight: 500;
  font-size: 1rem;
  margin-left: 5px;
  cursor: pointer;
  padding-top: 5px;
}

.form-select {
  background: transparent;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  z-index: 3;
}

.form-select:focus {
  background: transparent;
}

.form-group .see-more-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--light-green);
  top: 20%;
  right: 5%;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

.btn-create-action {
  color: white;
  height: 50px;
  width: 150px;
  border-radius: 15px;
  padding-top: 10px;
  font-weight: 600;
  background-image: linear-gradient(to right, var(--light-green), #6eddf8);
  float: right;
}

@media screen and (max-width: 1600px) {
  .generate-action-content .modal-header {
    height: 15%;
    padding-top: 0px;
  }
  .generate-action-content .modal-body {
    height: 70%;
    max-height: 80%;
    overflow-y: auto;
  }
  .generate-action-content .modal-footer {
    height: 15%;
    border-top: 1px solid #d6d6d6;
  }
}
@media screen and (max-width: 991px) {
  .generate-action-content .modal-header {
    height: 10%;
    padding-top: 0px;
  }
  .generate-action-content .modal-body {
    height: 80%;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .generate-action-content .modal-footer {
    height: 10%;
    border-top: 1px solid #d6d6d6;
  }
  .see-more-label {
    display: none !important;
  }
}
@media screen and (max-width: 576px) {
  .btn-create-action {
    height: 43px;
    width: 200px;
    padding-top: 7px;
  }
  .section-container-start-inspection {
    padding: 10px 15px;
  }
  .generate-action-content .modal-header {
    padding-top: 16px;
  }
  .generate-action-content {
    height: 100% !important;
  }
  .btn-create-action {
    width: 200px;
  }
}
@media screen and (max-width: 330px) {
  .btn-create-action {
    height: 35px;
    width: 200px;
    padding-top: 5px;
  }
}
/* doc-editor modal styles */
.close {
  top: 5px;
  right: 10px;
  position: absolute;
  zoom: 1.5;
  z-index: 1;
}

.modal-dialog {
  overflow-y: initial !important;
}

.doc-editor-modal {
  height: 92vh !important;
  width: 100vw !important;
  max-width: unset !important;
}

.doc-editor-content {
  height: 100% !important;
  width: 100% !important;
  /* padding: 30px 40px !important; */
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.m3-table-container {
  overflow-x: auto;
}

.m3-table {
  width: 100%;
  color: #4D4D4D;
  display: table;
}

.m3-table th {
  border: none;
  padding: 10px;
  color: var(--dark-green);
}

.m3-table tbody td {
  padding: 10px;
}

.table-icon {
  height: 17px;
  padding-right: 3px;
}

.table-action-icon {
  height: 17px;
  padding-right: 3px;
  cursor: pointer;
}

.empty-table-text {
  text-align: center;
  color: #166F73;
  font-size: 1.5em;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.empty-table-text-sm {
  text-align: center;
  color: #166F73;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

table.rounded-corners {
  border-spacing: 0;
  border-collapse: separate;
}

/* Apply border with border radius at top for first row in tbody */
table.rounded-corners > tbody > tr:first-child > td {
  border-top: 1px solid #D6D6D6;
}

table.rounded-corners > tbody > tr:first-child > td:first-child {
  border-top-left-radius: 10px;
}

table.rounded-corners > tbody > tr:first-child > td:last-child {
  border-top-right-radius: 10px;
}

/* Apply border to bottom of last row in tbody */
table.rounded-corners > tbody > tr:last-child > td {
  border-bottom: 1px solid #D6D6D6;
}

table.rounded-corners > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}

table.rounded-corners > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

/* Apply a border to the bottom of all but the last row */
table.rounded-corners > tbody > tr:not(:last-child) > td {
  border-bottom: 1px solid #D6D6D6;
}

/* Apply left border to first td in tbody */
table.rounded-corners > tbody > tr > td:first-child {
  border-left: 1px solid #D6D6D6;
}

/* Apply right border to last td in tbody */
table.rounded-corners > tbody > tr > td:last-child {
  border-right: 1px solid #D6D6D6;
}

.m3-tooltip {
  position: relative;
  display: inline-block;
}

.m3-tooltip .m3-tooltiptext {
  visibility: hidden;
  background-color: #FFFFFF;
  color: #4D4D4D;
  text-align: center;
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: 0px 4px 14px rgba(150, 150, 150, 0.25);
  white-space: nowrap;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 103%;
  top: 50%;
  transform: translate(0%, -50%);
}

.m3-tooltip:hover .m3-tooltiptext {
  visibility: visible;
}

.tooltip-option:hover {
  background-color: rgb(230, 230, 230);
}